export enum AccessLevel {
  Selection = -6,
  ViewHistory = -5,
  Back = -4,
  Discard = -3,
  SaveAndNew = -2,
  Save = -1,
  None = 0,
  View = 1,
  New = 2,
  Edit = 4,
  Delete = 8,
  Print = 16,
  Export = 32,
  Send = 64,
}

export enum InputType {
  None = 0,
  Text = 1,
  Email = 2,
  Phone = 3,
  Mobile = 4,
  Number = 5,
  Date = 6,
  IsActive = 7,
  Amount = 8,
  Dropdown = 9,
  HTML = 10,
  Index = 11,
  DateTime = 12,
  SubReport = 13,
  MultiSelect =14,
  MonthYear=15, // Nidhi 13-08-2024 Gid.: #865
}

export enum HireSlip {
  None = 0,
  Load = 1,
  Unload = 2,
}

export enum DocumentType {
  None = 0,
  CPOSUser = 1,
  ZotaStoreDoc = 2,
  StoreNearBy = 8,
  StorePic = 21,
  ColorPic = 26,
  FurniturePic = 28,
  BrandingPest = 30,
  StockPaymentDetail = 41,
  AccountStockPaymentDetail = 42,
}

// ActionField = 9

export enum ShortCutKeys {
  None = -1,
  Alt0 = 0,
  Alt1 = 1,
  Alt2 = 2,
  Alt3 = 3,
  Alt4 = 4,
  Alt5 = 5,
  Alt6 = 6,
  Alt7 = 7,
  Alt8 = 8,
  Alt9 = 9,
  AltS = 10,
  AltF = 11,
  AltC = 12,
  PageUp = 13,
  PageDown = 14,
  Home = 15,
  End = 16,
  Delete = 17,
  AltA = 18,
  AltR = 19,
  AltX = 20,
  Enter = 21,
}

export enum KEY_CODE {
  ENTER = 13,
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  PAGE_UP = 33,
  PAGE_DOWN = 34,
  END = 35,
  HOME = 36,
  DELETE = 46,
}
// Git Id: 781  Owner: Pankaj Motwani  Date: 26/04/2024 
// Comment: Added Sales Return With Tax In Enum
export enum CPOSEnum {
  None = 0,
  User = 1,
  UserRegistration = 2,
  UserRights = 3,
  StoreList = 4,
  LiveStoreList = 5,
  StoreInfo = 6,
  StorePo = 7,
  POSSale = 22,
  SAPSale = 23,
  SalesMISReport = 10,
  StorePoDetail = 11,
  PaymentOustanding = 12,
  WarehouseStock = 13,
  StoreStock = 14,
  Report_Day_wise_average_Sale = 37,
  Report_State_wise_Report = 38,
  Report_GSTR1 = 39,
  Report_GSTR2 = 40,
  Report_Sales_Report = 41,
  Report_Distributor_Dispatch = 45,
  Report_Fast_Moving_Item = 46,
  Report_Slow_Moving_Item = 47,
  Report_Store_Purchase_Details = 49,
  Report_Saving = 50,
  Report_Demand_Vs_Supply = 55,
  Report_BookingList = 53,
  Rivigo_Create_Booking = 54,
  Scheme_Master = 59,
  Product_Master = 60,
  Salt_Master = 61,
  Other_Brand = 62,
  Welcome_Kit = 63,
  Document_Library = 64,
  Chat_Support = 92,
  Sales_Return_Without_Tax = 87,
  Document_Process = 89,
  StoreExecution_Process = 90,
  Employee = 91,
  CatagotyMapping = 93,
  StoreCatagoryPoints = 94,
  adjustPoints = 95,
  storevendormapping = 97,
  schemeTransaction = 99,
  notificationList = 102,
  tabsequence = 103,
  Return_Policy = 106,
  Batch_Recall = 107,
  CashBook = 121,
  BillScheme = 111,
  schemeDetails = 123,
  storenotificationlist = 124,
  notificationremarklist = 125,
  storeexecutiontracker = 126,
  updateinactivestore = 127,
  schemereport = 128,
  Report_Request = 130,
  Notification_Master = 131,
  gstReport = 132,
  gstSalesInvoiceReport = 133,
  gstSalesReturnReport = 134,
  gstPurchaseInvoiceReport = 135,
  gstPurchaseReturnReport = 136,
  TermsAndConditions_Master = 138,
  // #433 Nidhi 14-09-2023 GST HSN Report
  gstHSNReport=139,
  // #434 Nidhi 14-09-2023 GST Docs Report
  gstDocsReport=140,
  batchwisesalesreport = 141,
  batchWiseSalesTertiaryReport = 142,
  Document_Process_ReApproval = 143,
  //~~~~~~~~~~ Pankaj 07-12-2023 GID: 542
  Product_Movemet_Dump_Data = 145,
  //~~~~~~~~~~ Pankaj 08-12-2023 GID: 546
  productwisesalessecondaryreport = 146,
  productwisesalestertiaryreport = 147,
  productwisesalesprimaryreport = 148,  
  //~~~~~~~~~~ Tushar 05-12-2023 GID: 535
  batchWiseSalesSecondaryReport = 149,
  StoreExecutionProcessHistroy = 153,
  OrderStockAssessmentReport = 154,
  ReasonMaster = 161,//~~~~~~~~~~ Rahul 2024-05-07 GID: 792
  //~~~~~~~~~ Nidhi 16/04/2024  #1
  Sales_Summary_Report=158,
  //~~~~~~~~~ Nidhi 23/04/2024  #2
  Sales_Dump_Report=159,
  //~~~~~~~~~ Nidhi 26/04/2024  #3
  Sales_Return_Report=160,
   //~~~~~~~~~ Nidhi 08/05/2024  #4
   Purchase_Order_Summary_Report=162,
    //~~~~~~~~~ Nidhi 08/05/2024  #6
    Purchase_Return_Report=163,
    //~~~~~~~~~ Nidhi 09/05/2024  #5
    Purchase_Order_Dump_Report=164,
    //~~~~~~~~~ Nidhi 09/05/2024  #7
    Stock_Report=165,
    POS_GRN_Reference_Report=166, //~~~~Nidhi 24/05/2024 #802
    Refill_Reminder_Report=167, //~~~~Nidhi 24/05/2024 #809
    Month_Wise_Sales_Report=169 //~~~~ Nidhi 05-08-2024 #865
}

export enum ConfirmationHeader {
  Confirmation,
  Warning,
  Notification,
  Success,
  Error,
}

export enum POStatus {
  UnApprove = 0,
  Approved = 1,
  Rejected = 2,
  Dispatch = 3,
  Hold = 4,
}

export enum StoreExeDocType {
  ShopPictures = 1,
  ColorPictures = 2,
  FurniturePictures = 3,
  BrnadingPictures = 4,
  Aadharfront = 5,
  AadharBack = 6,
  PanNoFront = 7,
  PanNoBack = 8,
  GstNoFront = 9,
  GstNoBack = 10,
  VideoName = 11,
  _20_20B = 12,
  _21_21B = 13,
  _21c = 14,
  StoreNearbyImages = 15,
  InvitationCard = 16,
  RentAgreement = 17,
  DocumentofProperty = 18,
  Selfie = 19,
  Digitalsign = 20,
  PloicydigSign = 21,
  paymentdetails = 22,
  _20f = 23,
  StockPaymentDetail = 24,
  // added by ashish
  StoreLocationImages = 25,
  AccountStockPaymentDetails = 42,
  DLChallanReceipt = 43,
}
///Always Sync Eith Table StoreExecutionRightsMaster
export enum StoreExecutionRights {
  FirmDetails = 1,
  StoreDetails = 2,
  PrivacyPolicy = 3,
  DlDetails = 4,
  StoreAgreement = 5,
  PaymentDetails = 6,
  InfrastructureDetails = 7,
  BrandingDetails = 8,
  FinalisationDetails = 9,
  OpeningDetails = 10,
  // PersionDetails = 11,
  // AccountDetails = 12,
  // DispatchDetails = 13
  AccountDetails = 11,
  DispatchDetails = 12,
  FollowUpRemarks = 13,
}

///use for assign role tab Table StoreExecutionRightsMaster
export enum DocumentTabRoleRights {
  PersionDetails = 1,
  FirmDetails = 2,
  StoreDetails = 0,
  PrivacyPolicy = 3,
  Secondarydetails = 4,
  DlDetails = 4,
  StoreAgreement = 5,
  PaymentDetails = 6,
}
export enum StoreExecutiomTabRoleRights {
  InfrastructureDetails = 0,
  BrandingDetails = 1,
  FinalisationDetails = 2,
  OpeningDetails = 5,
  AccountDetails = 3,
  DispatchDetails = 4,
  FollowUpRemarks = 6,
}

///manage In API Also
export enum StoreReturnType {
  InitialReturn = 1,
  FirstTimeMonthReturn = 2,
  Every6MonthReturn = 3,
  SpecificReturn = 4,
}
