import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { CommonService, DateService } from '../../common-services/common-functions.service';
import { CPOSEnum, InputType, ShortCutKeys } from '../../common-services/enum.service';
import { MY_DATE_FORMATS, MY_DATE_FORMATS_NEW } from '../../common-services/indian-dateadapter.service';
import { IFilterGridInput, IHashTable, IShortCutKeys } from '../../common-services/interface.service';
import { cellDef } from '../../common-services/models.service';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { SharedService } from '../../shared/shared.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger, MatChipInputEvent } from '@angular/material';

@Component({
  selector: 'app-filter-grid',
  templateUrl: './filter-grid.component.html',
  styleUrls: ['./filter-grid.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS_NEW }
  ],
})
export class FilterGridComponent implements OnInit, OnDestroy {
  searchData: IFilterGridInput;
  filterForm: FormGroup;
  headerName = '';
  private focusedKey: string;
  autoList: any[];
  private watcher: Subscription;
  isAllSelectedState = {};
  stateremovable = true;
  autoSearch = {};
  selectedItems = {};
  selectedCheckOutItems = {};
  statelastFilter: '';
  selectedItemValueSplitted = {};
  autoSearch1 = {};
  dispvalue : any = "";
  selectedMonth : any ="Sales Month From";
  selectedMonth1 : any ;
  //~~~~start  Nidhi 30-08-2024 GID:#907
  selectedMonthto : any ="Sales Month To";
  selectedMonthto1 : any ;
  minselectedmonthyear: Date;
  //~~~ End GID:#907

  constructor(public dialogRef: MatDialogRef<FilterGridComponent>, private notification: SnackBarService,
    @Inject(MAT_DIALOG_DATA) private confModel: IFilterGridInput, private fg: FormBuilder, private sharedService: SharedService) {
    // this.searchData = CommonService.copyObject(confModel);
    this.searchData = Object.assign({}, confModel);
    this.filterForm = fg.group({});
    this.generateFormGroup(confModel.searchCell);
    this.headerName = this.setHeaderName(confModel.pageEnum);
    CommonService.focusControlById(confModel.focusControl, 650);
  }

  ngOnInit() {
    
    this.watcher = this.sharedService.shortCutKeyListner.subscribe((t: IShortCutKeys) => {
      this.shortcutKeyEvent(t);
    });
  }

  shortcutKeyEvent(t: IShortCutKeys) {
    switch (t.key) {
      case ShortCutKeys.AltR:
        this.onResetClick();
        break;
      case ShortCutKeys.AltX:
        this.onCancelClick();
        break;
      case ShortCutKeys.Enter:
        this.onOkClick();
        break;
      default:
        break;
    }
  }

  private generateFormGroup(searchCell) {
    searchCell.forEach((t: cellDef) => {
      switch (t.fieldType) {
        case InputType.Date:
        case InputType.DateTime:
          this.filterForm.addControl(t.displayName + '_from', new FormControl(this.getValue(t.displayName + '_from', t.fieldType)));
          this.filterForm.addControl(t.displayName + '_to', new FormControl(this.getValue(t.displayName + '_to', t.fieldType)));
          break;
        case InputType.Email:
        case InputType.Text:
        case InputType.Dropdown:
        case InputType.HTML:
        case InputType.Index:
          this.filterForm.addControl(t.displayName, new FormControl(this.getValue(t.displayName, t.fieldType)));
          if (t.autoSearchKey) {

            this.filterForm.get(t.displayName).valueChanges
              .pipe(debounceTime(150))
              .switchMap(val => this.sharedService.autoCompleteFilter(val, this.focusedKey))
              .subscribe((data: any) => {
                this.autoList = [];
                if (data) {
                  this.autoList = data;
                }
              });
          }
          break;
        case InputType.Number:
          this.filterForm.addControl(t.displayName, new FormControl(this.getValue(t.displayName, t.fieldType), [Validators.pattern(CommonService.regExPatern.decimal)]));
          break;
        case InputType.Mobile: 
        case InputType.Phone:
          this.filterForm.addControl(t.displayName, new FormControl(this.getValue(t.displayName, t.fieldType), [Validators.pattern(CommonService.regExPatern.number)]));
          break;
        case InputType.MultiSelect:

          this.filterForm.addControl(t.displayName, new FormControl(this.getValue(t.displayName, t.fieldType)));
          if (t.autoSearchKey) {
            this.autoSearch[t.displayName] = [];
            this.sharedService.customGetApi('common/autosearch?keyName=' + t.autoSearchKey).subscribe((x: any) => {
              if (x.data) {
                this.autoSearch[t.displayName] = x.data;
                this.selectedCheckOutItems[t.displayName] =   this.autoSearch[t.displayName] ;
                if (!isNullOrUndefined(this.selectedItemValueSplitted[t.displayName])  && this.selectedItemValueSplitted[t.displayName].length > 0) {
                  this.selectedItemValueSplitted[t.displayName].forEach(element => {
                    var found = x.data.find((s) => s.name.toLowerCase() === element.toLowerCase());
                    if (found != null) {
                      this.statetoggleSelection(found, t.displayName);
                    }
                  });
                }
              }
            });
            this.filterForm.get(t.displayName).valueChanges.pipe(
              startWith<string | any[]>(''),
              map(name => typeof name === 'string' ? name : this.statelastFilter),
              map(filter => this.statefilter(filter, t.displayName))
            ).subscribe(data =>
              {this.selectedCheckOutItems[t.displayName] = data;
                this.filterselecteddata(t.displayName);
              });
         }
          break;
        case InputType.MonthYear://~~~~~Nidhi 13-08-2024 Gid.: #865 
             this.filterForm.addControl(t.displayName + '_from',new FormControl(new Date()));
            this.filterForm.addControl(t.displayName + '_from1', new FormControl(this.getValue(t.displayName +'_from', t.fieldType)));
            //~~~~start  Nidhi 30-08-2024 GID:#907
            this.filterForm.addControl(t.displayName + '_to',new FormControl(new Date()));
            this.filterForm.addControl(t.displayName + '_to1', new FormControl(this.getValue(t.displayName +'_to', t.fieldType)));
             //~~~~ End GID : #907
          break;
        default:
          break;
      }
    });
  }

  setHeaderName(pageEnum: CPOSEnum) {
    switch (pageEnum) {
      default:
        return CPOSEnum[pageEnum] ? CPOSEnum[pageEnum].toString().replace(/_/g, ' ') : '';
    }
  }

  getValue(controlName, fieldType = 0) {

    if (!isNullOrUndefined(this.searchData.prevSearch) && this.searchData.prevSearch.length > 0) {
      let name = null;
      this.searchData.prevSearch.forEach((t: IHashTable) => {
        if (t.key === controlName && !isNullOrUndefined(t.value) && t.value !== '') {
          if (fieldType == InputType.Date) {
            name = new Date(t.value)
          }
          else if (fieldType == InputType.MultiSelect) {
            this.selectedItemValueSplitted[t.key] = [];
            t.value.split(",").forEach(element => {
              this.selectedItemValueSplitted[t.key].push(element);
            })
          }
          //~~~~~ Nidhi 13-08-2024 Gid.: #865 
          else if(fieldType == InputType.MonthYear){
            if (t.key.indexOf('_from') !== -1){
            this.selectedMonth=t.value;
            this.selectedMonth1=t.value;}
            if (t.key.indexOf('_to') !== -1){
              this.selectedMonthto=t.value;
              this.selectedMonthto1=t.value;}

            name = t.value
          }
          else {
            name = t.value
          }
        }
      });
      return name;
    }
  }

  onOkClick() {
    
    const searchObj = new Array<IHashTable>();
    let obj: IHashTable = null;
    let dispName;
    let count = 0;
    let daycount = 0;
    this.searchData.searchCell.forEach((t) => {
      if(t.fieldType == 14){
        if(!isNullOrUndefined(this.selectedItems[t.displayName])){
          this.dispvalue = this.selectedItems[t.displayName].filter(e => e.selected === true).map(a => a.name).join();
          dispName = t.displayName;
          this.filterForm.controls[dispName].setValue(this.dispvalue);
        }       
      }    
    }  
  );

    
    if (this.filterForm.valid) {
      
      this.searchData.searchCell.forEach((t) => {
        if(t.fieldType === 15)
          {
            this.filterForm.controls[t.displayName+'_from'].setValue(this.selectedMonth1);
            this.filterForm.controls[t.displayName+'_to'].setValue(this.selectedMonthto1);
          }   
        dispName = (t.fieldType === InputType.Date || t.fieldType === InputType.DateTime || t.fieldType === InputType.MonthYear  ) ? t.displayName + '_from' : t.displayName;
        if (!isNullOrUndefined(this.filterForm.controls[dispName]) && !isNullOrUndefined(this.filterForm.controls[dispName].value) && this.filterForm.controls[dispName].value !== '') {
          switch (t.fieldType) {
            case InputType.Date:
            case InputType.DateTime:         
              if (!isNullOrUndefined(t.defDateDurationInDays) && t.defDateDurationInDays != null && t.defDateDurationInDays != 0) {
                if (!isNullOrUndefined(DateService.getUTCDate(this.filterForm.controls[dispName].value)) && DateService.getUTCDate(this.filterForm.controls[dispName].value) !== ''
                  && DateService.getUTCDate(this.filterForm.controls[t.displayName + '_to'].value) && DateService.getUTCDate(this.filterForm.controls[t.displayName + '_to'].value)) {
                  var date1 = new Date(DateService.getUTCDate(this.filterForm.controls[dispName].value));
                  var date2 = new Date(DateService.getUTCDate(this.filterForm.controls[t.displayName + '_to'].value));

                  var Time = date2.getTime() - date1.getTime();
                  var Days = Time / (1000 * 3600 * 24); //Diference in Days
                  if (Days > t.defDateDurationInDays) {
                    count = 1;
                    daycount = t.defDateDurationInDays;
                  }
                }
              }
              searchObj.push({ key: dispName, value: DateService.getUTCDate(this.filterForm.controls[dispName].value) });
              searchObj.push({ key: t.displayName + '_to', value: DateService.getUTCDate(this.filterForm.controls[t.displayName + '_to'].value) });
              break;
            case InputType.MultiSelect:   
              searchObj.push({ key: dispName, value:   this.filterForm.controls[dispName].value });
              break;
            case InputType.MonthYear:// Nidhi 13-08-2024 Gid.: #865
              searchObj.push({ key: dispName, value: this.selectedMonth1});
              searchObj.push({ key: t.displayName+"_to", value: this.selectedMonthto1});
              break;
            default:
              searchObj.push({ key: dispName, value: this.filterForm.controls[dispName].value });
              break;
          }
        }
      });
    } else { this.notification.error("From Date cannot be greater than To Date"); return; }
    if (count == 0) {
      this.dialogRef.close(searchObj);
    }
    else {
      this.notification.error("From Date and To Date not more then " + daycount + " Day");
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onResetClick() {
    this.filterForm.reset();
    this.searchData.searchCell.forEach((t) => {
    this.isAllSelectedState[t.displayName] = false;
   // this.selectedCheckOutItems[t.displayName] && this.selectedCheckOutItems[t.displayName].forEach(x => x.selected = false);
   //this.selectedCheckOutItems[t.displayName].forEach(x => x.selected = false);

    /*---- 22-10-2024 GID : #980 Nishita ---Issues related to Reset Button as per discussion with Paresh Sir and Nidhi Ma'am*/
   if(!isNullOrUndefined(this.selectedCheckOutItems[t.displayName]))
    {
     this.selectedCheckOutItems[t.displayName].forEach(x => x.selected = false);
    }
     /*---- 22-10-2024 GID : #980 Nishita */
    this.selectedItems[t.displayName] = [];
    this.selectedItemValueSplitted[t.displayName] = [];
    /*---- 05-09-2024 GID : #907 Nidhi */
    this.selectedMonth="Sales Month From";
    this.selectedMonth1="";
    this.selectedMonthto="Sales Month To";
    this.selectedMonthto1="";
    /*---- End GID : #907 */
    });
  }

  ngOnDestroy(): void {
    if (!isNullOrUndefined(this.watcher)) {
      this.watcher.unsubscribe();
    }
  }

  focusKey(key) {
    this.focusedKey = key;
  }
  statetoggleSelectAll(displayName: string) {

    // this.selectedstate = [];
    // this.selectedItems[displayName] = []; //~~~~~~~~~~~~ Tushar 26-03-2024 GID 568
    this.isAllSelectedState[displayName] = !this.isAllSelectedState[displayName];
    const len = this.selectedCheckOutItems[displayName].length;
    if (this.isAllSelectedState[displayName]) { 
      for (let i = 0; i < len; i++) {
        this.selectedCheckOutItems[displayName][i].selected = true;
        //this.selectedItems[displayName].push(this.selectedCheckOutItems[displayName][i]); //~~~~~~~~~~~~ Tushar 2024-05-21 GID 801
      }
    } else {
      //~~~~~~~~~~~~ Tushar 26-03-2024 GID 568
      // this.selectedItems[displayName] = [];
      this.isAllSelectedState[displayName] = false;
      //~~~~~~~~~~~~ GID 568
      this.selectedCheckOutItems[displayName].forEach(x => x.selected = this.isAllSelectedState[displayName]);
    }
    //~~~~~~~~~~~~ Tushar 26-03-2024 GID 568
    this.filterselecteddata(displayName)
      //  this.setStateselectall(displayName);
    //~~~~~~~~~~~~ GID 568
  }
  stateremove(item: any, displayName: string): void {
    const index = this.selectedItems[displayName].findIndex(value => value.name === item.name);
    if (index >= 0) {
      this.selectedItems[displayName].splice(index, 1);
      const findex = this.selectedCheckOutItems[displayName].findIndex(value => value.name === item.name);
      if (findex >= 0) {
        this.selectedCheckOutItems[displayName][findex].selected = false;
      }
    }

      this.setStateselectall(displayName);

  }

  statetoggleSelection(item: any, displayName: string) {
    item.selected = !item.selected;
    
    if (!this.selectedItems[displayName])
      this.selectedItems[displayName] = [];

    if (item.selected) {
      this.selectedItems[displayName].push(item);
    }
    else {
      //~~~~~~~~~~~~~~ Tuhsar 04-01-2023 Gid.: 371, 601 
      //const findex = this.selectedCheckOutItems[displayName].findIndex(value => value.name === item.name);
      const findex = this.selectedCheckOutItems[displayName].findIndex(value => value.id === item.id);
      //~~~~~~~~~~~~~~ Gid.: 371, 601 
      if (findex >= 0) {
        this.selectedCheckOutItems[displayName][findex].selected = false;
      }
      //~~~~~~~~~~~~~~ Tuhsar 04-01-2023 Gid.: 371, 601
      //const i = this.selectedItems[displayName].findIndex(value => value.name === item.name);
      const i = this.selectedItems[displayName].findIndex(value => value.id === item.id);
      //~~~~~~~~~~~~~~ Gid.: 371, 601
      if (i >= 0) {
        this.selectedItems[displayName].splice(i, 1);
      }
    }
   
    
    this.setStateselectall(displayName);
  }

  setStateselectall(displayName) {
    //~~~~~~~~~~~~ Tushar 26-03-2024 GID 568
    // const allitemcount = this.autoSearch[displayName].length;
    const allfiltereitemcountitemcount = this.selectedCheckOutItems[displayName].length;
    const checkeditemcount = this.selectedCheckOutItems[displayName].filter(x => x.selected === true).length;
    if (allfiltereitemcountitemcount === checkeditemcount) {
    //~~~~~~~~~~~~ GID 568
      this.isAllSelectedState[displayName] = true;
    } else {
      this.isAllSelectedState[displayName] = false;
    }
    //this.filterForm.get(displayName).setValue(this.selectedItems[displayName].filter(e=>e.selected == true).map(a=> a.name).join());
  }
  statefilter(filter, displayName) {
    
    this.statelastFilter = filter;
    if (filter) {
      return this.autoSearch[displayName].filter(option => {
        return option.name != null && option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
      });
    } else {
      if (this.autoSearch[displayName]) {
        return this.autoSearch[displayName].slice();
      }
    }
  }
  //~~~~~~~~~~~~ Tushar 26-03-2024 GID 568
  filterselecteddata(displayName)
  {
    if (this.autoSearch[displayName] != null && this.autoSearch[displayName] != 0) {
      this.isAllSelectedState[displayName] = false;
      this.selectedItems[displayName]=[];
      const len = this.autoSearch[displayName].length;
      for (let i = 0; i < len; i++) {
        if (this.autoSearch[displayName][i].selected == true)
        {
          this.selectedItems[displayName].push(this.autoSearch[displayName][i]);
        }
      }
      this.setStateselectall(displayName);
    }
  }
  //~~~~~~~~~~~~ GID 568

    //~~~~~~~~~~~~ Nidhi 13-08-2024 Gid.: #865 
    chosenMonthHandler(normlizedMonth, datepicker) {    
        var tempDate = JSON.stringify(normlizedMonth).replace("\"",'').split("-")
       var month = parseInt(tempDate[1])
        var year = month == 12?parseInt(tempDate[0])+1:parseInt(tempDate[0])
        var year = month == 12?parseInt(tempDate[0])+1:parseInt(tempDate[0])
        month = month == 12?1:month+1;
        this.selectedMonth = month+"/"+year;
        this.selectedMonth1 = month+"/"+year;
       const fromdate = new Date(normlizedMonth);
       this.minselectedmonthyear = new Date(fromdate.getFullYear(),fromdate.getMonth(),fromdate.getDate());
        datepicker.close();
      } 
       //~~~~~~~~~~~~ GID #865
       //~~~~start  Nidhi 30-08-2024 GID:#907
       chosenMonthHandlerto(normlizedMonth, datepicker) {
        var tempDate = JSON.stringify(normlizedMonth).replace("\"",'').split("-")
       var month = parseInt(tempDate[1])
        var year = month == 12?parseInt(tempDate[0])+1:parseInt(tempDate[0])
        var year = month == 12?parseInt(tempDate[0])+1:parseInt(tempDate[0])
        month = month == 12?1:month+1;
        this.selectedMonthto = month+"/"+year;
        this.selectedMonthto1 = month+"/"+year;
        datepicker.close();
      }
       //~~~~End   GID:#907
}
