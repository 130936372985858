import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAlphanumericOnly]'
})
export class AlphanumericOnlyDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    const keyCode = event.keyCode;
    const charCode = event.which;

    if ((keyCode >= 48 && keyCode <= 57) || // Numbers
        (keyCode >= 65 && keyCode <= 90) || // Uppercase letters
        (keyCode >= 97 && keyCode <= 122) || // Lowercase letters
        keyCode === 8 || // Backspace
        keyCode === 9 || // Tab
        keyCode === 37 || // Left arrow
        keyCode === 39 || // Right arrow
        keyCode === 46 || // Delete
        keyCode === 32) { // Space
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

}
