import { Component, Input, OnInit, Inject, Output, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-remark-preview',
  templateUrl: './remark-preview.component.html',
  styleUrls: ['./remark-preview.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class RemarkPreviewComponent implements OnInit {
  remarkValue: string;
  expenseType: string;

  constructor(public dialogRef: MatDialogRef<RemarkPreviewComponent>) { }

  pageProperties = { previewImage: null, selectedDoc: null, showProgress: false };

  ngOnInit() { 
    this.remarkValue = localStorage.getItem('remarkValue') === 'null' ? 'No Remarks' : localStorage.getItem('remarkValue');
    this.expenseType = localStorage.getItem('expenseType');
  }

  onCancelClick() {
    this.dialogRef.close();
  }
}
