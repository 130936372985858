import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { AppInitialize } from '../app-init.service';
import { ButtonType, ConfirmationModel, ConfirmationPopupComponent } from '../common-component/confirmation-popup/confirmation-popup.component';
import { CommonService } from '../common-services/common-functions.service';
import { AccessLevel, ConfirmationHeader } from '../common-services/enum.service';
import { IShortCutKeys } from '../common-services/interface.service';
import { ApiResponse, CommonFilter } from '../common-services/models.service';

@Injectable()
export class SharedService {

  sharedMaster = {};
  tableConfiguration = {};
  reportConfiguration = {};
  notificationCnt: number = 0;
  // ddl = { 1: { api: 'state/ddlist', list: new Array<any>() } };
  shortCutKeyListner = new Subject<IShortCutKeys>();
  showLoader = new BehaviorSubject(false);
  autoSearchArray = {};
  masterUrl = {
    1: { 'List': 'account/users?search=', 'Save': 'account/create', 'Delete': 'account/delete/', 'Filter': 'payment/filter', 'isExists': '', 'byId': 'account/user/' }
  };

  searchProgress = false;
  printObject = null;
  formBtn = {
    actionAccess: AccessLevel.None
  };

  storeAssignment = {
    assignedStore: [],
    assignedState: [],
    assignedDistrict: []
  };

  stateDistrict = {
    stateList: [],
    districtList: [],
    totalStore: 0
  };

  pageRouteConfig = {
    // Git Id: 13  Developer: Pankaj Motwani  Date: 06/01/2025
    11: { pageRoute: 'master/updatepostatus/', retriveField: 'id' },
    59: { pageRoute: 'user/scheme/', retriveField: 'sM_Id' },
    60: { pageRoute: 'master/item/', retriveField: 'id' },
    61: { pageRoute: 'master/salt/', retriveField: 'salt_Id' },
    62: { pageRoute: 'master/otherbrand/', retriveField: 'obM_Id' },
    63: { pageRoute: 'master/welcomekit/', retriveField: 'wel_Id' },
    64: { pageRoute: 'master/documentlibrary/', retriveField: 'doc_Id' },
    89: { pageRoute: 'store/documentprocess/', retriveField: 'storeId' },
    90: { pageRoute: 'store/execution/', retriveField: 'storeId' },
    91: { pageRoute: 'user/employee/', retriveField: 'empId' },
    95: { pageRoute: 'user/adjustpoints/', retriveField: 'spid' },
    99: { pageRoute: 'user/schemetransaction/', retriveField: 'storeId' },
    103: { pageRoute: 'store/tabsequence/', retriveField: 'tab_Type' },
    106: { pageRoute: 'stockreturn/returnpolicy/', retriveField: 'id' },
    107: { pageRoute: 'batchrecall/recall/', retriveField: 'id' },
    111: { pageRoute: 'master/proportionateschememrp/', retriveField: 'id' },
    123: { pageRoute: 'user/schememasterdetails/', retriveField: 'schemeId' },
    131: { pageRoute: 'master/Notification/', retriveField: 'notification_Id' },
    138: { pageRoute: 'master/TermsAndConditions/', retriveField: 'termsAndConditions_Id' },
    143: { pageRoute: 'store/documentprocess/', retriveField: 'storeId', versionField: 'versionNo' },
    153: { pageRoute: 'store/execution/', retriveField: 'storeId', versionField: 'versionNo' },
    161: { pageRoute: 'master/Reason/', retriveField: 'reason_Id'}, //~~~~~~~~~~ Rahul 2024-05-07 GID: 792
    172: { pageRoute: 'master/manipulatecashbookcategory/expense/', retriveField: 'id'}, // Git Id: 20  Date: 16-01-2025  Developer: Pankaj Motwani
    173: { pageRoute: 'master/manipulatecashbookcategory/income/', retriveField: 'id'}, // Git Id: 21  Date: 17-01-2025  Developer: Pankaj Motwani
    174: { pageRoute: 'master/pettyCashAllocation/', retriveField:'dsea_id' }, // Date: 28/01/2025  Developer: Dipak Zala Git Id: 22
    177: { pageRoute: 'user/loyaltyDiscountConfiguration/', retriveField: 'id'}
  };

  constructor(public dialog: MatDialog, private httpClient: HttpClient, private appInit: AppInitialize) { }
  public isEnableOrVisible(no): boolean {
    return (this.formBtn.actionAccess & no) > 0;
  }

  getConfirmatonConfig(header: ConfirmationHeader, message, _buttonType: ButtonType = ButtonType.YesNo, data: any = null) {
    return this.dialog.open(ConfirmationPopupComponent, {
      width: '400px',
      data: new ConfirmationModel(header, message, _buttonType, data),
      disableClose: true,
      closeOnNavigation: true
    });
  }

  public get StateDistrict(): Observable<any> {
    if (this.stateDistrict.stateList.length === 0) {
      return this.StateDistrictByUser(0);
    } else {
      return Observable.of(CommonService.copyObject(this.stateDistrict));
    }
  }

  public StateDistrictByUser(userid = 0): Observable<any> {
    return this.customGetApi('store/defaultstoredata?userid=' + userid).map((t: any) => {
      this.stateDistrict.stateList = t.states;
      this.stateDistrict.districtList = t.district;
      this.stateDistrict.totalStore = t.totalStore;
      return CommonService.copyObject(this.stateDistrict);
    });
  }

  getTableConfiguration(page: any) {
    if (this.tableConfiguration[page] && this.tableConfiguration[page].length > 0) {
      return Observable.of({ data: this.tableConfiguration[page] });
    } else {
      return this.customGetApi('common/tblconfig?type=' + page).map((t) => {
        this.tableConfiguration[page] = t.data;

        return t;
      }).catch(err => Observable.throw(err));
    }
  }

  getReportConfiguration(page: any) {
    if (this.reportConfiguration[page]) {
      return Observable.of({ data: this.reportConfiguration[page] });
    } else {
      return this.customGetApi('common/reportconfig?type=' + page).map((t) => {

        const filter = this.getSearchFilter(page);
        if (t && t.data && t.data.hasFromToDateField ) {
          const d = new Date();
          d.setDate(d.getDate() - (t.data.defDateDurationInDays > 0 ? t.data.defDateDurationInDays : 30));

          filter.filterArray.push({ key: t.data.hasFromToDateField + '_from', value: d });
          filter.filterArray.push({ key: t.data.hasFromToDateField + '_to', value: new Date() });

        }
        if(!isNullOrUndefined(t.data.lstFilterData) ){
          t.data.lstFilterData.forEach(element=>{
            filter.filterArray.push({key : element.defaultSearchField , value : element.defaultSearchValue})
          })

        }
        this.sharedMaster[page].searchFilter = filter;
        this.reportConfiguration[page] = t.data;
        return t;
      }).catch(err => Observable.throw(err));
    }
  }

  getSearchFilter(page: any, _pageSize = 0) {
    if (isNullOrUndefined(this.sharedMaster[page])) {
      this.sharedMaster[page] = new FilterObject(new CommonFilter(page, (_pageSize > 0 ? _pageSize : this.appInit.gridItemsPerPage)));
    }
    return this.sharedMaster[page].searchFilter;
    //let filter = new CommonFilter();
    //if (!isNullOrUndefined(this.sharedMaster[page])) {
    //    filter = this.sharedMaster[page].searchFilter;
    //} else {
    //    filter.pageNumber = 1;
    //    filter.pageSize = pageSize;
    //}
    //return filter;
  }

  // searchDropdownList(ddlFor, param: string = '') {
  //   return this.httpClient.get(this.ddl[ddlFor].api + param).map((t: ApiResponse) => t.data);
  // }

  filter(master: any, param1: any = null, queryParam: any = null): Observable<any> {
    if (!isNullOrUndefined(queryParam)) {
      return this.httpClient.post<ApiResponse>(this.masterUrl[master].Filter + queryParam, param1).map(t => t);
    } else {
      return this.httpClient.post<ApiResponse>(this.masterUrl[master].Filter, param1).map(t => t);
    }
  }

  getFilter(filter): Observable<any> {
    return this.httpClient.post<any[]>('common/filter', filter).map(t => t);
  }

  getList(master: any, param1: any = ''): Observable<any> {
    return this.httpClient.get<any[]>(this.masterUrl[master].List + param1).map(t => t);
  }

  // customGetApi(api: string): Observable<ApiResponse> {
  //
  //   this.showLoader.next(true);
  //   return this.httpClient.get<ApiResponse>(api).map((t) => {{
  //     this.showLoader.next(false);
  //   }
  //     return t;
  //   }).catch((err: any) => {
  //
  //     this.showLosssader.next(false);
  //     return Observable.throw(err)
  //   });

  // }

  customGetApi(api: string): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(api).map(t => t).catch(err => Observable.throw(err));
  }


  customPostApi(api: string, data: any): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(api, data).map(t => t).catch(err => Observable.throw(err));
  }


  // customPostApi(api: string, data: any): Observable<any> {
  //   this.showLoader.next(true);
  //   return this.httpClient.post(api, data,{ responseType: 'blob', observe: 'response' }).map((t) => {{
  //     this.showLoader.next(false);
  //   }

  //     return t;
  //   }).catch((err: any) => {
  //     return Observable.throw(err)
  //   });

  // }

  // customPostApi(api: string, data: any): Observable<ApiResponse> {
  //   this.showLoader.next(true);
  //   return this.httpClient.post<ApiResponse>(api, data).map((t) => {{
  //     this.showLoader.next(false);
  //   }

  //     return t;
  //   }).catch((err: any) => {
  //     return Observable.throw(err)
  //   });

  // }

  previewImage(url: string, filePath: string): Observable<any> {
    if (!isNullOrUndefined(filePath) && filePath !== '') {
      return this.httpClient.get(url + encodeURIComponent(filePath), { responseType: 'blob', observe: 'response' });
    } else {
      return Observable.of(null);
    }
  }

  previewReport(url: string, data: any): Observable<any> {
    return this.httpClient.post(url, data, { responseType: 'blob', observe: 'response' });
  }

  uploadFile(_file, dataParam: { id: string, storeCode: string, docType: number }, url = 'common/upload') {
    if (_file) {
      let formData = new FormData();
      formData.set('content', _file, _file.name);
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }
      return this.httpClient.post<any>(url, formData, { params: RequestParams });
    }
  }

  uploadbase64File(_file, dataParam: { StoreId: number, DocumentMasterId: number, docType: number }, url = 'common/uploadbase64') {
    if (_file) {
      let formData = new FormData();
      formData.set('content', _file, _file.name);
      let RequestParams = new HttpParams();
      if (dataParam) {
        Object.keys(dataParam).forEach(function (key) {
          RequestParams = RequestParams.append(key, dataParam[key]);
        });
      }

      return this.httpClient.post<any>(url, formData, { params: RequestParams });
    }
  }

  autoCompleteFilter(val: any, value: any) {

    const searchval = val ? val : '';
    if (value && (!this.autoSearchArray[value] || this.autoSearchArray[value].length == 0)) {
      return this.customGetApi('common/autosearch?keyName=' + value).map(t => {
        if (t.data) {
          const key = Object.keys(t.data[0])[0];
          this.autoSearchArray[value] = t.data.map(x => x[key]);
          return this.autoSearchArray[value].filter(option => option.toLowerCase().includes(searchval.toLowerCase()));
        } else {
          return [];
        }
      });
    } else {
      return Observable.of(((this.autoSearchArray[value]) ? this.autoSearchArray[value].filter(option => option.toLowerCase().includes(searchval.toLowerCase())) : []));
    }
  }

  notificationCount() {
    this.customGetApi('storenotification/notificationcount').subscribe((t: any) => {
      if (t.status) {
        this.notificationCnt = t.data;
      }
    });
  }

}

export class FilterObject {
  constructor(private _searchFilter: any = null, private _dataSource: any = null) {
    this.searchFilter = isNullOrUndefined(_searchFilter) ? new CommonFilter() : _searchFilter;
    this.dataSource = _dataSource;
  }
  searchFilter: CommonFilter;
  dataSource = [];

}

// let expenseName = '';
    // if (!isNullOrUndefined(expenseID) && expenseID > 0) {
    //   const list: any = this.getDropdownList(MastersEnum.Expense);
    //   if (!isNullOrUndefined(list) && list.value.length > 0) {
    //     list.value.filter((e) => {
    //       if (e.id == expenseID) {
    //         expenseName = e.name;
    //       }
    //     });
    //   }
    // }
    // return expenseName;
    // getDropdownList(ddlFor) {
  //   const obj = this.ddl[ddlFor];
  //   if (!isNullOrUndefined(obj)) {
  //     if (!isNullOrUndefined(obj.list) && obj.list.length > 0) {
  //       return Observable.of(obj.list);
  //     } else {
  //       return this.httpClient.get(obj.api).map((t: any) => {
  //         if (t.status === true) {
  //           this.ddl[ddlFor].list = t.data;
  //           return t.data;
  //         } else {
  //           return Observable.throw(t.message);
  //         }
  //       });
  //     }
  //   } else {
  //     Logger.Warn(`getDropdownList Not Exists ${ddlFor}`);
  //     return Observable.of(null);
  //   }
  // }

  // getDropdownName(id: number, pageNo: VendorEnum, matchkey = 'id', resultKey = 'name') {
  //   let name = '';
  //   if (!isNullOrUndefined(id) && id > 0) {
  //     const list: any = this.getDropdownList(pageNo);
  //     if (!isNullOrUndefined(list) && list.value.length > 0) {
  //       list.value.filter((e) => {
  //         if (e[matchkey] == id) {
  //           name = e[resultKey];
  //         }
  //       });
  //     }
  //   }
  //   return name;
  // }

  // save(master: any, param1: any = null, queryParam: any = null): Observable<ApiResponse> {
  //   return this.httpClient.post<ApiResponse>(this.masterUrl[master].Save, param1).map(t => t);
  // }

  // isExists(master: any, param1: any = null): Observable<ApiResponse> {
  //   return this.httpClient.get<ApiResponse>(this.masterUrl[master].isExists + param1);
  // }

  // byId(master: any, param1: any = null): Observable<any> {
  //   return this.httpClient.get<ApiResponse>(this.masterUrl[master].byId + param1).map(t => t);;
  // }

  // delete(master: any, param1: any = null, queryParam: any = null): Observable<any> {
  //   return this.httpClient.post<any>(this.masterUrl[master].Delete, param1).map(t => t);
  // }

  // deleteById(master: any, param1: any = null, queryParam: any = null): Observable<any> {
  //   return this.httpClient.get<any>(this.masterUrl[master].Delete + param1).map(t => t);
  // }
