import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatLabel, MatTableDataSource } from '@angular/material';
import { ImageListPreviewComponent } from '../image-list-preview/image-list-preview.component';
import { AuthService } from '../../auth/auth.service';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { SharedService } from '../../shared/shared.service';
import { isNullOrUndefined } from 'util';
import { SelectionModel } from '@angular/cdk/collections';
import { CommonService } from '../../common-services/common-functions.service';
import { Documents } from '../../common-services/models.service';

export interface ExpenseCashBook {
  categoryName: string;
  amount: number;
  accept: boolean;
  reject: boolean;
  reason: string;
}

@Component({
  selector: 'app-expense-cash-book',
  templateUrl: './expense-cash-book.component.html',
  styleUrls: ['./expense-cash-book.component.css']
})
export class ExpenseCashBookComponent implements OnInit {
  allowToChange = false;
  SelectedCashbook: any;
  columns: string[];
  rejectColumns: string[];
  storeCode: string;
  showCashBookRejected = false;
  showCashBook = false;
  noCashBookRecExist = true;
  selection = new SelectionModel<ExpenseCashBook>(true, []);
  dataSourceExpenseCashBook: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  dataSourceExpenseCashBookReject: MatTableDataSource<any> = new  MatTableDataSource<any>([]);
  cposheaderId: number;
  ExpenseList: any;
  ExpenseDetailsList: any;
  ExpenseDetailsImageList: any;
  IsLoaderShow: boolean = false
  private dialogImageRef: MatDialogRef<ImageListPreviewComponent>;
  constructor(private dialog: MatDialog, private authService: AuthService, public dialogRef: MatDialogRef<ExpenseCashBookComponent>,
    private snackbar: SnackBarService, private sharedService: SharedService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.getDisplayedColumns();
    this.IsLoaderShow = true;
    this.SelectedCashbook = data.header;
    this.allowToChange = true;
    // this.allowToChange = (isNullOrUndefined(this.SelectedCashbook.status) || this.SelectedCashbook.status === '');
    this.sharedService.customGetApi('cashbook/getcashbookdetail?Id=' + this.SelectedCashbook.cposId).subscribe((x: any) => {
      if (x.status == true) {
        this.ExpenseList = x.data.header;
        this.cposheaderId = this.ExpenseList.cposId;
        this.storeCode = this.ExpenseList.storeCode;
        
        if (x.data.details.length === 0 && x.data.rejectDetails.length === 0){
          this.noCashBookRecExist = false;
        }

        if (x.data.details.length > 0) {
          this.showCashBook = true;
        }

        this.ExpenseDetailsList = x.data.details;
        this.dataSourceExpenseCashBook.data = x.data.details;

        if (this.ExpenseList.dateOfApprovalOrReject === undefined || this.ExpenseList.dateOfApprovalOrReject === null){
          this.allowToChange = false;
        }

        this.ExpenseDetailsImageList = x.data.attachmentDetails;

        if (x.data.rejectDetails.length > 0) {
          this.showCashBookRejected = true;
          this.dataSourceExpenseCashBookReject.data = x.data.rejectDetails;
        }
        
        this.IsLoaderShow = false;
      } else {
        this.IsLoaderShow = false;
        this.snackbar.warning(x.message === '' ? 'Something went wrong' : x.message);
      }
    });
  }

  ngOnInit() {
  }

  displayedColumnsGroupMaster = [
    { def: "cposHeaderId", label: "Cpos Header Id", hide: true},
    { def: "cposDetailId", label: "Cpos Detail Id", hide: true},
    { def: "categoryName", label: "Category Name", hide: false },
    { def: "amount", label: "Amount", hide: false },
    { def: "image", label: "Image", hide: false },
    { def: "accept", label: "Approve", hide: false },
    { def: "reject", label: "Reject", hide: false },
    { def: "reason", label: "Reason", hide: false },
  ];

  displayColumnDetailReject = [
    { def: "cposHeaderId", label: "Cpos Header Id", hide: true},
    { def: "cposDetailId", label: "Cpos Detail Id", hide: true},
    { def: "categoryName", label: "Category Name", hide: false },
    { def: "amount", label: "Amount", hide: false },
    { def: "reason", label: "Reason", hide: false },
  ]

  getDisplayedColumns() {
    this.columns = this.displayedColumnsGroupMaster
      .filter((cd) => !cd.hide)
      .map((cd) => cd.def);

    this.rejectColumns = this.displayColumnDetailReject
      .filter((cd) => !cd.hide)
      .map((cd) => cd.def);
  }

  OnSave(){
    if (this.dataSourceExpenseCashBook.data.filter(item => item.accept === false && item.reject === false).length > 0){
      this.snackbar.warning("It's mandatory to either accept or reject expense category!");
      return;
    }
    else if (this.dataSourceExpenseCashBook.data.filter(item => item.reject === true && item.reason === '').length > 0) {
      this.snackbar.warning("Every reason for a rejected expense category must be provided!");
      return;
    }
    
    this.sharedService.customPostApi('cashbook/approverejectcashbookreason?cposHeaderId=' + this.cposheaderId, 
      this.dataSourceExpenseCashBook.data
    ).subscribe((t: any) => {
      if (t.status == true) {
          this.snackbar.success(t.message);
          this.dialogRef.close(this.SelectedCashbook);
        } else {
          this.snackbar.error(t.message);
      }
    });
  }

  previewDoc(item) {
    if (item) {
      this.dialogImageRef = this.dialog.open(ImageListPreviewComponent, {
        width: String(CommonService.mainSectionLW.width - 100) + 'px',
        height: String(CommonService.mainSectionLW.height - 100) + 'px',
        disableClose: false,
        closeOnNavigation: false
      });
      this.dialogImageRef.componentInstance.isPreviewFirst = true;
      this.dialogImageRef.componentInstance.boxHeight = CommonService.mainSectionLW.height - 200;
      this.dialogImageRef.componentInstance.isPopup = true;
      this.dialogImageRef.componentInstance.apiUrl = 'common/getfile?filePath=';
      if (item.fileName == null && item.path == null) {
        this.dialogImageRef.componentInstance.docuementList = this.ExpenseDetailsImageList.filter(a => a.dCusExpDet_Id == item.detailId);
      }
      else {
        this.dialogImageRef.componentInstance.docuementList = [new Documents(item.fileName, item.path)];
      }
    }
  }

  onCancelClick(){
    this.dialogRef.close();
  }    

  isAllAcceptSelected() {
    const numSelected = this.dataSourceExpenseCashBook.data.filter(item => item.accept === true).length;
    const numRows = this.dataSourceExpenseCashBook.data.length;
    return numSelected === numRows;
  }

  isAllRejectSelected() {
    const numSelected = this.dataSourceExpenseCashBook.data.filter(item => item.reject === true).length;
    const numRows = this.dataSourceExpenseCashBook.data.length;
    return numSelected === numRows;
  }

  masterToggleAccept(event:any) {
    if (event) {
        this.dataSourceExpenseCashBook.data.forEach(item => {item.accept = event.checked, item.reject = !event.checked; });
      }
    this.dataSourceExpenseCashBook = new MatTableDataSource(this.dataSourceExpenseCashBook.data);
  }

  masterToggleReject(event:any) {
    if (event) {
      this.dataSourceExpenseCashBook.data.forEach(item => {item.reject = event.checked, item.accept = !event.checked; });
    }
    this.dataSourceExpenseCashBook = new MatTableDataSource(this.dataSourceExpenseCashBook.data);
  }

  checkAcceptChanged(event:any, cposDetailId:number){
    if (event && cposDetailId) {
      var index = this.dataSourceExpenseCashBook.data.findIndex(a => a.cposDetailId == cposDetailId);
      this.dataSourceExpenseCashBook.data[index].accept = event.checked;
      this.dataSourceExpenseCashBook.data[index].reject = !event.checked;
    }
  }

  checkRejectChanged(event:any, cposDetailId:number){
    if (event && cposDetailId) {
      var index = this.dataSourceExpenseCashBook.data.findIndex(a => a.cposDetailId == cposDetailId);
      this.dataSourceExpenseCashBook.data[index].reject = event.checked;
      this.dataSourceExpenseCashBook.data[index].accept = !event.checked;
    }
  }
}