import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ChangeHistoryComponent } from '../common-component/change-history/change-history.component';
import { CustomToolTipComponent } from '../common-component/custom-tool-tip/custom-tool-tip.component';
import { FileListviewComponent } from '../common-component/file-upload/file-listview/file-listview.component';
import { FileUploadComponent } from '../common-component/file-upload/file-upload.component';
import { FormButtonsComponent } from '../common-component/form-buttons/form-buttons.component';
import { ImageListPreviewComponent } from '../common-component/image-list-preview/image-list-preview.component';
import { RemarkPreviewComponent } from '../common-component/remark-preview/remark-preview.component';
import { MatTableComponent } from '../common-component/mat-table/mat-table.component';
import { BytesPipe } from './byte.pipe';
import { HoverClassDirective } from './hover-class.directrive';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { SafePipe } from './safe-resource.pipe';
import { ToolTipRendererDirective } from './tool-tip-renderer.directive';
import { GrdFilterPipe, FilterPipe } from './filter.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ChangePasswordComponent } from '../common-component/change-password/change-password.component';
import { MapControlComponent } from '../common-component/map-control/map-control.component';
import { ExpenseCashBookComponent } from '../common-component/expense-cash-book/expense-cash-book.component';
import { ExpenseBookComponent } from '../common-component/expense-book/expense-book.component';
import { UpdateInactivestoreComponent } from '../cpos-modules/store-process/update-inactivestore/update-inactivestore.component';
// Git Id: 13  Developer: Pankaj Motwani  Date: 06/01/2025
import { UpdatePoStatusComponent } from '../cpos-modules/master/update-po-status/update-po-status.component';
import { AlphanumericOnlyDirective } from './alphanumeric-only.directive';
// import {} from 'googlemaps';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTreeModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSortModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatTabsModule,
    MatBadgeModule,
    ScrollDispatchModule,
    DragDropModule,
    MatRippleModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatGridListModule,
    ReactiveFormsModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatMomentDateModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatTreeModule,
    MatDialogModule,
    MatProgressBarModule,
    MatTableComponent,
    MatTooltipModule,
    FormButtonsComponent,
    MatSortModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    NumberToWordsPipe,
    MatChipsModule,
    MatTabsModule,
    MatStepperModule,
    MatBadgeModule,
    SafePipe,
    BytesPipe,
    GrdFilterPipe,
    FilterPipe,
    HoverClassDirective,
    ToolTipRendererDirective,
    FileUploadComponent,
    FileListviewComponent,
    ImageListPreviewComponent,
    RemarkPreviewComponent,
    CustomToolTipComponent,
    ChangeHistoryComponent,
    ScrollDispatchModule,
    DragDropModule,
    MatRippleModule,
    ChangePasswordComponent,
    MapControlComponent
  ],
  declarations: [
    MatTableComponent,
    FileUploadComponent,
    FormButtonsComponent,
    NumberToWordsPipe,
    HoverClassDirective,
    SafePipe,
    BytesPipe,
    GrdFilterPipe,
    FilterPipe,
    FileListviewComponent,
    ImageListPreviewComponent,
    RemarkPreviewComponent,
    CustomToolTipComponent,
    ToolTipRendererDirective,
    ChangeHistoryComponent,
    ChangePasswordComponent,
    MapControlComponent,
    ExpenseBookComponent,
    ExpenseCashBookComponent,
    // Git Id: 13  Developer: Pankaj Motwani  Date: 06/01/2025
    UpdatePoStatusComponent,
    UpdateInactivestoreComponent,
    AlphanumericOnlyDirective
  ],
  entryComponents: [CustomToolTipComponent, ChangeHistoryComponent, ImageListPreviewComponent, RemarkPreviewComponent, ChangePasswordComponent, MapControlComponent, ExpenseBookComponent, ExpenseCashBookComponent, UpdatePoStatusComponent, UpdateInactivestoreComponent]
})
export class SharedModule { }
