import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SnackBarService } from '../../common-services/snack-bar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiResponse, CommonFilter } from '../../common-services/models.service';
import { CPOSEnum } from '../../common-services/enum.service';
import { SharedService } from '../../shared/shared.service';
import { CommonService, DateService } from '../../common-services/common-functions.service';
import { FormServices } from '../../common-services/validators.service';
import { isNullOrUndefined } from 'util';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-email-scheduler-component',
  templateUrl: './email-scheduler-component.component.html',
  styleUrls: ['./email-scheduler-component.component.css']
})
export class EmailSchedulerComponentComponent implements OnInit {
  emailSchedularForm: FormGroup;
  emaildata: any;
  filtervalue: CommonFilter;
  showfiltervalue: CommonFilter;
  chipObject = {};
  page: CPOSEnum;
  FilterList: any;
  RequestExportList: any;
  constructor(public dialogRef: MatDialogRef<EmailSchedulerComponentComponent>,
    private notification: SnackBarService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private sharedService: SharedService) {
    this.bindForm(null);
    this.emaildata = data.searchdata;
    this.chipObject = data.chipDisplay;
    this.filtervalue = data.showDisplay.filterArray;
    this.page = data.searchdata.currentPage;
    this.RequestExportList = data.RequestExportList;

   
    this.binddata(this.page);


  }



  ngOnInit() {
  }
  bindForm(hasVal) {
    this.emailSchedularForm = this.fb.group({
      'ReportPageId': this.page,
      'ES_FileName': [hasVal ? hasVal.eS_FileName : null],
      'ES_To': [hasVal ? hasVal.eS_To : null],
      'ES_CC': [hasVal ? hasVal.eS_CC : null],
      'ES_Subject': [hasVal ? hasVal.eS_Subject : null],
      'ES_Body': [(hasVal ? hasVal.eS_Body : null), Validators.required],
      'ES_TriggerTime': [(hasVal ? hasVal.eS_TriggerTime : null), Validators.required],
      'Exportcolumn': [this.RequestExportList ? this.RequestExportList : null]
    });
  }

  binddata(pageId: Number) {
    this.sharedService.customGetApi("emailschedule/getreportmail?pageid=" + pageId).subscribe((t) => {
      if (t.status && t.data != null) {
        this.bindForm(t.data[0]);
      }
    });
  }
  onSave() {
    if (this.emailSchedularForm.valid) {




      //        FilterColumn=ReportRequest.FilterColumn,
      //        Exportcolumn=   ReportRequest.Exportcolumn,
      //        ES_To="neelam.p@ethicsinfotech.in",
      //        ES_Subject="test",
      //        ES_Body="test"
      const saveObj = CommonService.copyObject(this.emailSchedularForm.value);
      saveObj.cposFilter = this.emaildata;
      this.sharedService.customPostApi('emailschedule/saveupdate', saveObj).subscribe((t: ApiResponse) => {
        if (t.status === true) {
          this.notification.success('Save Email Scheduler Successfully');
          this.dialogRef.close();
        } else {
          this.notification.warning(t.message);
        }
      }, (err) => {
        console.log(err);
      });
    } else {
      FormServices.focusFormValidationErrors(this.emailSchedularForm);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }



}
export class GetProcedureparameter
{
  constructor (private _name,private _datatype)
  {
   this.name = _name;
   this.datatype = _datatype;
  
  }
  name :string;
  datatype :string;
}
