import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { CommonService, Logger } from '../common-services/common-functions.service';
import { SharedService } from '../shared/shared.service';
import { NavItem, NavService } from './menu-service/nav.service';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit {

  opened = true;
  over = 'side';
  notificationCnt = 0;
  expandHeight = '42px';
  collapseHeight = '42px';
  displayMode = 'flat';
  stickClass = 'leak_add';
  menuSearch = new FormControl();
  pageArray = {
    companyList: [],
    visitList: []
  }
  @ViewChild('appDrawer', { static: false }) appDrawer: ElementRef;
  watcher: Subscription;
  userFullName;
  navItemsCopy = [];
  constructor(mediaObserver: MediaObserver,
    private navService: NavService,
    private authService: AuthService,
    public sharedService: SharedService) {
    this.watcher = mediaObserver.media$.subscribe((change: MediaChange) => {
      // this.activeMediaQuery = change ? `'${change.mqAlias}' = (${change.mediaQuery})` : '';
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        this.opened = false;
        this.over = 'over';
      } else {
        this.opened = true;
        this.over = 'side';
      }
    });
    // this.watcher = media.subscribe((change: MediaChange) => {
    //   if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
    //     this.opened = false;
    //     this.over = 'over';
    //   } else {
    //     this.opened = true;
    //     this.over = 'side';
    //   }
    // });
    this.userFullName = authService.getFullName;
    this.loadDefaultData();
    this.bindFreqentlyAccessPage();
  }

  navItems: NavItem[] = [
    {
      title: 'Dashboard',
      icon: 'dashboard',
      link: '',
    },
    {
      title: 'Admin',
      icon: 'security',
      children: [
        {
          title: 'Users',
          icon: 'supervised_user_circle',
          link: '/cpos/admin/list',
        },
        {
          title: 'Roles Right',
          icon: 'layers',
          link: '/cpos/admin/rights/1',
        },
        {
          title: 'Users Right',
          icon: 'layers',
          link: '/cpos/admin/rights/2',
        }
      ]
    },
    {
      title: 'Master',
      icon: 'blur_on',
      children: [
        {
          title: 'State',
          icon: 'list_alt',
          link: '/cpos/master/state',
        },
        {
          title: 'City',
          icon: 'list_alt',
          link: '/cpos/master/city',
        },
        {
          title: 'Company',
          icon: 'business',
          link: '/cpos/master/company',
        },
        {
          title: 'Destination',
          icon: 'location_on',
          link: '/cpos/master/destination',
        },
        {
          title: 'Expense',
          icon: 'list_alt',
          link: '/cpos/master/expense',
        },
      ]
    },
  ];

  private bindFreqentlyAccessPage() {
    try {
      const vl = localStorage.getItem('vl');
      if (vl) {
        const list = JSON.parse(atob(vl));
        this.pageArray.visitList = list.filter((t) => {
          if (t.count <= 5) {
            return t;
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  ngOnInit() {
    Logger.Normal(this.authService.getUserMenu);
    this.navItems = this.authService.getUserMenu as NavItem[];
    this.navItemsCopy = this.authService.getUserMenu as NavItem[];
    this.stickClass = (!this.navService.restrictClose) ? 'leak_add' : 'leak_remove';

    // this.sharedService.notificationCount();

    this.menuSearch.valueChanges.pipe(debounceTime(200)).subscribe((searchvalue) => {
      const filterMenuList = JSON.parse(JSON.stringify(this.navItemsCopy))
      this.navItems = filterMenuList.filter(f => {
        if (f.title.toLowerCase().includes(searchvalue.toLowerCase()) || (f.children && f.children.some(c => c.title.toLowerCase().includes(searchvalue.toLowerCase())))) {
          if (f.children && searchvalue) {
            f.children = f.children.filter(c => c.title.toLowerCase().includes(searchvalue.toLowerCase()));
          }
          return f;
        }
      });
    });
  }

  loadDefaultData(): any {

  }

  onStickSideNavClick() {
    if (this.stickClass === 'leak_add') {
      this.stickClass = 'leak_remove'
      this.navService.restrictClose = true;
    } else {
      this.stickClass = 'leak_add'
      this.navService.restrictClose = false;
    }
  }
  LogOut() {
    this.authService.logOut();
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.watcher.unsubscribe();
  }


}

